import { createStore } from 'vuex';
import { AuthModule } from './modules/AuthModule';
import { VisitsModule } from './modules/VisitsModule';
import { PermitsModule } from './modules/PermitsModule';
import { DriversModule } from './modules/DriversModule';
import { InvoiceModule } from './modules/InvoiceModule';
import { TrucksModule } from './modules/TrucksModule';
import { PartnersModule } from './modules/PartnersModule';
import { PolygonsModule } from './modules/PolygonsModule';
import { UsersModule } from './modules/UsersModule';
import i18n from '../i18n';

export default new createStore({
    state: {
        user: {
            first_name: false,
            second_name: false,
            last_name: false,
            user_role: false,
            e_mail: false,
            phone_number: false,
            token: '',
        },
        is_auth: false,
        layout: 'app',
        is_show_sidebar: false,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'en', name: 'English' },
            { code: 'ru', name: 'Russian' },
        ],
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = 'ru';
            i18n.global.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = false;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        is_auth(state) {
            return state.is_auth
        }
    },
    actions: {},
    modules: {
        AuthModule,
        DriversModule,
        InvoiceModule,
        PolygonsModule,
        PartnersModule,
        PermitsModule,
        TrucksModule,
        UsersModule,
        VisitsModule,
    },
});
